@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-700 text-white;
  transition: background 500ms;
}

a,
input,
label {
  @apply antialiased font-bold;
  filter: drop-shadow(0 0 0.25rem #666);
}

a {
  @apply text-blue-200;
}
a:visited {
  @apply text-pink-300;
}

input {
  @apply text-center cursor-pointer bg-transparent;
}
input:focus {
  @apply outline-none;
}
label {
  @apply cursor-pointer;
}

.to-fade {
  @apply px-2 py-1 text-sm text-center text-white bg-gray-800 rounded opacity-0;
}

@keyframes fadein {
  0% {
    @apply opacity-0;
    transform: translateY(0);
  }
  10%,
  90% {
    @apply opacity-100;
    transform: translateY(-2rem);
  }
  100% {
    @apply opacity-0;
    transform: translateY(-2rem);
  }
}

.granimate {
  @apply h-screen w-screen absolute inset-0;
  opacity: 0.42;
  z-index: -1;
  background: linear-gradient(
      217deg,
      rgba(255, 0, 0, 0.8),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
}

@keyframes cloud {
  from {
  }
  to {
  }
}
